@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        scrollbar-width: none;
        scroll-behavior: smooth;
    }
    *::-webkit-scrollbar {
        width: 0;
    }
    html {
        scroll-behavior: smooth;
        scroll-padding-top: 0;
    }
    body {
        @apply font-primary;
        overflow-x: hidden;
        width: 100vw;
    }

    h1 {
        @apply font-secondary;
    }
}

@layer components {
    .btn {
        @apply px-10;
    }
}

@font-face {
    font-family: 'MonumentExtended-Regular';
    src: url('./assets/fonts/MonumentExtended-Regular.otf') format('otf'),
        url('./assets/fonts/MonumentExtended-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'MonumentExtended-Bold';
    src: url('./assets/fonts/MonumentExtended-Ultrabold.otf') format('otf'),
        url('./assets/fonts/MonumentExtended-Ultrabold.woff') format('woff');
    font-weight: 800;
    font-display: swap;
    font-style: normal;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
